<script lang="ts">
  import routes from '../../../routing/routes'
  import { state } from '../../../store/state'

  const { newContent } = state

  const excludedRoute = ['home', 'new-content', 'dynamic', 'saved-search']

  let buttonItems: any[] = routes.filter((item) => !item.id.endsWith('s') && !excludedRoute.includes(item.id))
</script>

<div class="flex flex-col w-full h-full min-h-[96vh] items-center">
  <div
    class="new-contents h-[80%] max-w-[100rem] flex flex-wrap justify-evenly items-center pt-[15vh] gap-20 w-full p-4"
  >
    {#each buttonItems as item}
      <button
        class:hidden={item.id === 'liveblog'}
        on:click={() => newContent.specialize(item.path)}
        class={`h-40 w-64 flex flex-col items-center justify-center bg-accent rounded-custom bg-a ${item.id}`}
        id={`qa-test-content-${item.id}`}
      >
        <div class="h-24 w-24">
          <svelte:component this={item.icon} />
        </div>
        <p class="text-lg font-semibold text-white">
          New {item.id}
        </p>
      </button>
    {/each}
  </div>
</div>

<style lang="postcss">
  .new-contents :global(svg > *),
  .new-contents :global(svg > * > *) {
    stroke: white;
  }

  .new-contents :global(svg > circle),
  .video :global(svg .cls-1) {
    fill: white;
  }

  .new-contents > button {
    position: relative;
    overflow: hidden;
  }

  .new-contents > button::after {
    content: '';
    position: absolute;
    top: -200%;
    left: -200%;
    width: 600%;
    height: 600%;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    animation: moveGloss 2s linear infinite;
  }

  .new-contents > button:hover::after {
    opacity: 1;
    animation:
      moveGloss 2s linear infinite,
      fadeIn 2s ease-in-out forwards;
  }

  @keyframes moveGloss {
    0% {
      transform: translateX(-50%) translateY(-50%);
    }
    100% {
      transform: translateX(50%) translateY(50%);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
